import {WorkItemClass} from "../work-item.class";
import {ConsumerControlClass} from "../consumer-control.class";

export class IntakeSubmitClass {
  constructor(
    public workItemCreateRequest: WorkItemClass = new WorkItemClass(),
    public intakeConsumerControlResource: ConsumerControlClass = new ConsumerControlClass()
  ){

  }
}
