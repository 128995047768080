import {Injectable, OnInit} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {LookupManagerService} from "../common/lookup-manager.service";
import {Observable, Observer} from "rxjs";

@Injectable()
export class lookupResolver implements Resolve<any>{
  constructor(private lookup: LookupManagerService, private router: Router){
  };

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log(route);
    console.log(state);
    return this.resolvedLookups(route.routeConfig.path);
  }

  resolvedLookups(path):Observable<any>{

    let lookupObs:Observable<any>
    if(LookupManagerService.lookupTypes[path]) {
      lookupObs = Observable.create(Observer => {
        this.lookup.getLookUpValues(LookupManagerService.lookupTypes[path], LookupManagerService.english).then(res => {
          Observer.next(res);
          Observer.complete();
        }).catch(err => {
          console.log(err);
        });
      });

      return lookupObs.map((v) => {
        return v;
      })
    }else{
      return lookupObs;
    }

  }
}
