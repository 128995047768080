import { ProgramSelectedMainClass } from  './intake-program-selectedmain.class';
import {IntakeBaseBeanClass} from "./intake-base-bean.class";

export class ProgramSelected extends IntakeBaseBeanClass{
    constructor(
        public programSelected: ProgramSelectedMainClass = new ProgramSelectedMainClass(),
    ) {
      super();
    }
}
