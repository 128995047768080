
export class ProgramSelectedMainClass{

  constructor(
    public programId: number = undefined,
    public dirtyStatus: any = 'insert',
    public programName: string = '',
    public programStatusCode: string = '',
    public applicationDate: Date = null,
    public selectedIndicator: string = '',
    public programSubType: string = '',
    public programMode: string =  "",
    public waiverIndicator: string = "",
    public confirmProgramIndicator: string = "",
    public finalizeProgramIndicator: string = "",
  ){
  }
}
