export class ConsumerControlClass {
  constructor(
    public case_id:number = null,
    public vendorId:number = null,
    public active_ind:string = null,
    public screen_defn:string = null,
    public status:string = null,
    public assigned_to:string = null,
    public create_user_id:string = null,
    public update_user_id:string = null,
    public completedBy:string = null,
    public statusUpdate:boolean = null,
    public benefitsCaseStatus:string = null,
    public appCode:string = null,
    public consumerControlId:number = null,
  ) {


  }

}
