import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppConfirmPopupComponent } from './app-confirm-popup.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AppConfirmPopupComponent
  ],
  exports: [
    AppConfirmPopupComponent
  ]
})

export class AppConfirmPopupModule { }
