import {Address} from "../address.class";

export class SmartystreetAddressverifyClass {
  constructor(
    public originalAddress: Address,
    public suggestedAddress: Address[],
    public type: string, // home mail authRep or previousAddress
    public index: number,
    public select: any = 'original'
  ) { }
}

