export class WorkItemClass {
  constructor(
    public workItemDueDt: Date = null,
    public workItemTypeCd:string = '',
    public workItemSubTypeCd:string = '',
    public workItemDescription:string = '',
    public assingedTo:string = '',
    public createdBy:string = '',
    public updatedBy:string = '',
    public consumerControlId: number = null
  ){

  }
}
